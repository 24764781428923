@font-face {
  font-family: Aharoni;
  src: url(./fonts/ahronbd.ttf);
}

@font-face {
  font-family: Sen;
  src: url(./fonts/Sen-Regular.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Sen;
  src: url(./fonts/Sen-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Sen;
  src: url(./fonts/Sen-ExtraBold.ttf);
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Sen, sans-serif;
  /* background-color: black; */
}

code {
}

:root {
  --background: white;
  --panel: #f6f6f6;
  --primary: #479bfe;
  --secondary: #0c1a34;
  --tertiary: #000E3F;

  /* sizes */
  --header-height: 3.8rem;
  --footer-height: 5.4rem;
}

@media only screen and (min-width: 769px) {
  :root {
    --header-height: 5.4rem;
  }
}

button {
  font-family: Sen;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
}

.right {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.nav-link_active {
  font-weight: 600;
  position: relative;
}

.nav-link_active::after {
  content: "";
  display: block;
  border: 1px solid var(--background);
  background-color: var(--background);
  color: inherit;
  width: 100vw;
  height: 0;
  position: absolute;
  left: calc(100% + 1.5rem);
  bottom: calc(50% - 1px);
}

.header-link_active {
  color: var(--primary);
  font-weight: 600;
}

.snappable {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}