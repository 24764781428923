/* target pagination container */
.swiper-pagination {
  position: relative;
  padding: 1.5rem 0 1rem 0;
  
}
/* target all bullets */
.swiper-pagination-bullet {
	background-color: white !important;
	display: inline-block;
	width: 1rem !important;
	height: 1rem !important;
	opacity: 1 !important;
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: black !important;
	opacity: 1;
}
